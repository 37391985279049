import React, { Component } from 'react';
import { Carousel } from 'antd';
import handbag from '../assets/handbag.jpg';
import phone from '../assets/phone.jpg';
import camera from '../assets/camera.jpg';
import laptop from '../assets/laptop.jpg';
import louisVuitton from '../assets/louis-vuitton.jpg';
import richLaceSilver from '../assets/rich-lace-silver.jpg';
import macbook from '../assets/macbook.jpg';
import rolex from '../assets/rolex.jpg';

class PHeader extends Component {
    render() {
        return (
            <header className="header">
                <Carousel className="mobile-banner" autoplay>
                    <img src={phone} alt="phone" />
                    <img src={camera} alt="camera" />
                    <img src={laptop} alt="laptop" />
                    <img src={handbag} alt="handbag" />
                    <img src={rolex} alt="watch, rolex" />
                    <img src={macbook} alt="macbook in the table" />
                    <img src={richLaceSilver} alt="rich lace silver" />
                    <img src={louisVuitton} alt="louis vuitton bag" />
                </Carousel>
                <div className=" hide-for-sm">
                    <Carousel className="banner" autoplay>
                        <div className="desktop-banner">
                            <img src={phone} alt="phone" />
                            <img src={camera} alt="camera" />
                            <img src={laptop} alt="laptop" />
                            <img src={handbag} alt="handbag" />
                        </div>
                        <div className="desktop-banner">
                            <img src={rolex} alt="watch, rolex" />
                            <img src={macbook} alt="macbook in the table" />
                            <img src={richLaceSilver} alt="rich lace silver" />
                            <img src={louisVuitton} alt="louis vuitton bag" />
                        </div>
                    </Carousel>
                </div>
                <h1 className="page-title text-center text-primary">
                    ยินดีต้อนรับสู่<br/>
                    รับจำนำสุทธิสาร.com
                </h1>
            </header>
        )
    }
}

export default PHeader
