import React from 'react';
import { Layout, BackTop } from 'antd';
import PHeader from './components/PHeader';
import Main from './components/Main';
import Sidebar from './components/Sidebar';
import 'antd/dist/antd.css';
import './index.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const { Content, Footer } = Layout;

function App() {
  return (
    <div className="App container">
      <Layout>
        <PHeader />
        <Content>
          <Main />
          <Sidebar />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <BackTop />
          All right reserved. รับจำนำสุทธิสาร.com
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
