import React, { Component } from 'react';
import brandLogoLg from '../assets/products/brand-logo.jpg';

class Brand extends Component {
    render() {
        return (
            <p className="brand-image">
                <img src={brandLogoLg} srcSet={`${brandLogoLg} 800w`} alt="brand" />
            </p>
        )
    }
}

export default Brand
