import React, { Component } from 'react';
import Brand from './Brand';
import Rate from './Rate';
import ConditionTransfer from './ConditionTransfer';
import ConditionService from './ConditionService';
import ConditionPawn from './ConditionPawn';

class Sidebar extends Component {

    render() {
        return (
            <aside className="sidebar">
                <Brand />
                <Rate />
                <ConditionTransfer />
                <ConditionService />
                <ConditionPawn />
                <div className="fb-page" data-href="https://www.facebook.com/SutthisanCashExpress/" data-tabs="timeline" data-width="600" data-height="480" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/SutthisanCashExpress/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/SutthisanCashExpress/">รับจำนำสุทธิสาร</a></blockquote></div>
            </aside>
        );
    }
}

export default Sidebar