import React, { Component } from 'react';

class ConditionService extends Component {
    render() {
        return (
            <div className="detail-card">
                <h4 className="text-primary text-xl text-underline">
                    เงื่อนไขการให้บริการ
                </h4>
                <p>
                    ขั้นตอนการให้บริการ
                </p>
                <ol>
                    <li>
                        แจ้งความประสงค์ของท่าน ว่าต้องการนำสินค้าชนิดใดมาจำนำ
                        โดยแจ้งรุ่นสินค้า และประเมินราคาสินค้าในเบื้องต้น
                    </li>
                    <li>
                        กำหนดสถานที่นัดพบ โดยผู้จำนำต้องเตรียมเอกสาร สำเนาบัตรประชาชน
                        เซ็นรับรองสำเนา เพื่อยืนยันการเป็นเจ้าของสินค้า
                    </li>
                    <li>
                        ตรวจสอบสภาพ ตีราคา และรับเงินสดทันที<br/>
                        ระยะเวลาผ่อนชำระตั้งแต่ 60 วันขึ้นไป และสูงสุด 60 เดือน
                        อัตราดอกเบี้ยต่อปีไม่เกิน 15% ตามที่กฏหมายกำหนด เงิน 1,000 บาท
                        จะมีค่าบริการ 5 บาท/วัน ท่านโอนเงินค่าบริการทุก 20 วัน
                        (นับจากวันที่จำนำสินค้า) อัตราดอกเบี้ยร้อยละ 15 ต่อปี 
                        โดยอัตราดอกเบี้ยค่าปรับ ค่าบริการ และค่าธรรมเนียม ใดๆ 
                        เมื่อรวมกันแล้วสูงสุดไม่เกิน 28% ต่อปี
                    </li>
                </ol>
            </div>
        )
    }
}

export default ConditionService
