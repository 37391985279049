import React, { Component } from 'react';
import qrCode2 from '../assets/line-qr-2.jpg';
import { Icon } from 'antd';

class Contact extends Component {
    render() {
        return (
            <div className="">
                <h2 className="text-center text-primary" style={{fontSize: '1.75em'}}>
                    โทรสอบถามได้ที่เบอร์<br/>
                    คุณณรงค์ชัย &nbsp;
                    <a href="tel:0865595557" className="text-primary">
                        086-559-5557
                    </a><br/>
                    และ &nbsp;
                    <a href="tel:020589547" className="text-primary">
                        02-0589547
                    </a><br />
                    {/* <a href="https://line.me/ti/p/%40trade2hand" className="line-button" rel="noopener noreferrer" target="_blank">
                        <i className="fab fa-line"></i>&nbsp;
                        LineID: @trade2hand
                    </a> */}
                    <a href="https://lin.ee/o9EBcIy" className="line-button" rel="noopener noreferrer" target="_blank">
                        <i className="fab fa-line"></i>&nbsp;
                        LineID: @328iwzsn
                    </a>
                </h2>
                <p className="text-center" style={{padding: '0 3em', marginBottom: '1em'}}>
                    <a href="https://lin.ee/o9EBcIy" rel="noopener noreferrer" target="_blank">
                        <img src={qrCode2} width="200" style={{ margin: '0 auto' }} alt="qr code" />
                    </a>
                </p>
                <p className="text-primary">
                    <Icon type="home" /> <span className="text-underline">ร้านเราตั้งอยู่ที่</span>:
                    374-376 ถ.สุทธิสารวินิจฉัย แขวงสามเสนใน เขตพญาไท กทม. 10400
                    โทร. 02-0589547
                </p>
                <p style={{ padding: '1em' }}>
                    <iframe
                        title="shop location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.8240010344125!2d100.55756531483087!3d13.789480990323074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDQ3JzIyLjEiTiAxMDDCsDMzJzM1LjEiRQ!5e0!3m2!1sen!2sth!4v1620995773881!5m2!1sen!2sth"
                        width="600"
                        height="450"
                        style={{border: '0', maxWidth: '100%'}}
                        allowfullscreen=""
                        loading="lazy"
                    ></iframe>
                </p>
                <p>
                    มีเจ้าหน้าที่รับสินค้า โดยเราให้บริการท่านในเขต กรุงเทพฯ
                    เขตห้วยขวาง, ลาดพร้าว, ปทุมวัน, พระรามเก้า, สะพานควาย,
                    อนุสาวรีย์ชัยสมรภูมิ, จตุจักร, ดินแดง etc.
                </p>
            </div>
        )
    }
}

export default Contact
