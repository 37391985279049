import React, { Component } from 'react';
import MainDescription from './MainDescription';
import Gallery from './Gallery';
import RichLace from './RichLace';
import Contact from './Contact';

class Main extends Component {
    render() {
        return (
            <div className="main-content">
                <MainDescription />
                <Gallery />
                <hr />
                <RichLace />
                <hr />
                <Contact />
            </div>
        );
    }
}

export default Main