import React, { Component } from 'react';
import scbLogo from '../assets/scb-logo.png';
import kbankLogo from '../assets/kbank-logo.png';
import bayLogo from '../assets/bay-logo.png';

class ConditionTransfer extends Component {
    render() {
        return (
            <div className="condition-transfer detail-card">
                <h4 className="text-primary text-xl text-underline">
                    เงื่อนไขการโอนเงิน
                </h4>
                <p>
                    โอนเงินเข้าบัญชี ชื่อ <span className="text-primary text-lg"> ณรงค์ชัย เตวิชพงศ์</span>
                </p>
                <ul className="list-no-bullet" style={{ marginBottom: '1.5em' }}>
                    <li>
                        <img src={scbLogo} width="22" height="22" alt="scb logo" style={{marginRight: '0.5em'}} />
                        ธ. ไทยพาณิชย์ เลขบัญชี <span className="text-primary">218-246945-7</span>
                    </li>
                    <li>
                        <img src={kbankLogo} width="22" height="22" alt="scb logo" style={{marginRight: '0.5em'}} />
                        ธ. กสิกรไทย เลขบัญชี <span className="text-primary">768-2-09679-3</span>
                    </li>
                    <li>
                        <img src={bayLogo} width="22" height="22" alt="scb logo" style={{marginRight: '0.5em'}} />
                        ธ. กรุงศรีอยุธยา เลขบัญชี <span className="text-primary">154-1-48688-6</span>
                    </li>
                </ul>
                <ol>
                    <li>
                        ท่านโอนเงินค่าบริการ ทุก 20 วัน นับจากวันที่นำสินค้ามาจำนำ
                    </li>
                    <li>
                        เมื่อท่านโอนเงินแล้ว โทรหรือส่ง SMS หาเราที่เบอร์ 086-5595557
                        เพื่อบันทึกรายการ
                    </li>
                    <li>
                        จะไม่มีกำหนดระยะเวลาในการรับจำนำ ท่านสามารถฝากสินค้าได้โดยไม่มีกำหนด
                    </li>
                </ol>
            </div>
        )
    }
}

export default ConditionTransfer
