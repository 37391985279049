import React, { Component } from 'react';

class ConditionPawn extends Component {
    render() {
        return (
            <div className="detail-card">
                <h4 className="text-primary text-xl text-underline">
                    เงื่อนไขการรับจำนำ
                </h4>
                <ol>
                    <li>
                        ผู้นำสินค้ามาจำนำ ต้องเป็นเจ้าของสินค้า โดยเราจะไม่รับจำนำ
                        เครื่องเช่า เครื่องยืม หรือเครื่องบริษัท
                    </li>
                    <li>
                        สินค้าที่นำมาจำนำไม่ควรเกิน 1-2 ปี หากเกินจะพิจารณาเป็นบางรายการ
                        โดยสินค้าต้องอยู่ในสภาพดี ไม่เคยเสียหรือเคยซ่อมมาก่อน
                    </li>
                    <li>
                        เตรียมอุปกรณ์ สายชาร์จ แบตเตอรี่มาให้ครบ
                    </li>
                </ol>
            </div>
        )
    }
}

export default ConditionPawn
