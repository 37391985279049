import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <div className="detail-card" style={{marginTop: '0'}}>
                <h4 className="text-primary text-underline text-xl">
                    อัตราค่าบริการ
                </h4>
                <p>
                    ค่าบริการ ร้อยละ 0.5 / วัน โดยชำระค่าบริการทุก 20 วัน
                </p>
                <table className="rate-table">
                    <thead>
                        <tr>
                            <th>
                                ยอดจำนำ(บาท)
                            </th>
                            <th>
                                ค่าบริการ(บาท)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>6,000</td>
                            <td>วันละ 30</td>
                        </tr>
                        <tr>
                            <td>8,000</td>
                            <td>วันละ 40</td>
                        </tr>
                        <tr>
                            <td>10,000</td>
                            <td>วันละ 50</td>
                        </tr>
                        <tr>
                            <td>11,000</td>
                            <td>วันละ 55</td>
                        </tr>
                        <tr>
                            <td>12,000</td>
                            <td>วันละ 60</td>
                        </tr>
                    </tbody>
                </table>
                <h4 className="text-center" style={{color: '#d00'}}>
                    *** โดยยอดเงิน 1,000 บาท มีค่าบริการ 5 บาท / วัน
                </h4>
            </div>
        )
    }
}

export default Contact
