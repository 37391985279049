import React, { Component } from 'react';
import richLace_md from '../assets/products/rich-lace-md.jpg';
import richLace_sm from '../assets/products/rich-lace-sm.jpg';

class RichLace extends Component {
    render() {
        return (
            <div className="rich-lace">
                <img src={richLace_md} srcSet={`${richLace_sm} 300w, ${richLace_md} 768w`} alt="เลสหลวงพ่อรวย"  />
                <h4 className="text-primary">
                    รับจำนำ-รับเช่า-รับซื้อ เลสหลวงพ่อรวย (วัดตะโก) รุ่นต่างๆ <br className="hide-for-sm"/> 
                    ให้ราคาเป็นธรรม โดยพิจารณา ตามรุ่น สภาพและอุปกรณ์
                </h4>
            </div>
        );
    }
}

export default RichLace
