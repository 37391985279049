import React, { Component } from 'react';
import bag from '../assets/products/bag.jpg';
import bag_md from '../assets/products/bag-md.jpg';
import bag_sm from '../assets/products/bag-sm.jpg';
import belt from '../assets/products/belt.jpg';
import belt_sm from '../assets/products/belt-sm.jpg';
import belt_xs from '../assets/products/belt-xs.jpg';
import belt2 from '../assets/products/belt-2.jpg';
import belt2_sm from '../assets/products/belt-2-sm.jpg';
import belt2_xs from '../assets/products/belt-2-xs.jpg';
import wallet from '../assets/products/wallet.jpg';
import wallet_sm from '../assets/products/wallet-sm.jpg';
import wallet_xs from '../assets/products/wallet-xs.jpg';
import wallet2 from '../assets/products/wallet-2.jpg';
import wallet2_sm from '../assets/products/wallet-2-sm.jpg';
import wallet2_xs from '../assets/products/wallet-2-xs.jpg';

class Gallery extends Component {
    render() {
        return (
            <div className="gallery">
                <img src={bag} srcSet={`${bag_sm} 300w, ${bag_md} 768w, ${bag_md} 1280w`} alt="bag" />
                <img src={belt} srcSet={`${belt_xs} 300w, ${belt_sm} 768w, ${belt_sm} 1280w`} alt="belt" />
                <img src={belt2} srcSet={`${belt2_xs} 300w, ${belt2_sm} 768w, ${belt2_sm} 1280w`} alt="belt 2" />
                <img src={wallet} srcSet={`${wallet_xs} 300w, ${wallet_sm} 768w, ${wallet_sm} 1280w`} alt="wallet" />
                <img src={wallet2} srcSet={`${wallet2_xs} 300w, ${wallet2_sm} 768w, ${wallet2_sm} 1280w`} alt="wallet 2" />
            </div>
        );
    }
}

export default Gallery
