import React, { Component } from 'react';
import laptop from '../assets/products/laptop.jpg';
import laptop_md from '../assets/products/laptop-md.jpg';
import laptop_sm from '../assets/products/laptop-sm.jpg';
import laptop2 from '../assets/products/laptop-2.jpg';
import laptop2_md from '../assets/products/laptop-2-md.jpg';
import laptop2_sm from '../assets/products/laptop-2-sm.jpg';
import watch from '../assets/products/watch.jpg';
import watch_md from '../assets/products/watch-md.jpg';
import watch_sm from '../assets/products/watch-sm.jpg';
import watch2 from '../assets/products/watch-2.jpg';
import watch2_md from '../assets/products/watch-2-md.jpg';
import watch2_sm from '../assets/products/watch-2-sm.jpg';

class MainDescription extends Component {
    render() {
        return (
            <div className="main-description">
                <p className="main-products">
                    <img src={laptop} srcSet={`${laptop_sm} 300w, ${laptop_md} 768w, ${laptop_md} 1280w`} alt='laptop' />
                    <img src={laptop2} srcSet={`${laptop2_sm} 300w, ${laptop2_md} 768w, ${laptop2_md} 1280w`} alt='laptop 2' />
                    <img src={watch} srcSet={`${watch_sm} 300w, ${watch_md} 768w, ${watch_md} 1280w`} alt='watch' />
                    <img src={watch2} srcSet={`${watch2_sm} 300w, ${watch2_md} 768w, ${watch2_md} 1280w`} alt='watch 2' />
                </p>
                <p style={{textIndent: '2em'}}>
                    ผม แบน ณรงค์ชัย เตวิชพงศ์ เว็บผมให้บริการ ซื้อ-ขาย-ฝาก-จำนำ
                    สินค้าไอที โดยให้บริการทางด้านการเงินแก่ท่าน <b>ด้วยความเข้าใจ ให้เกียรติ 
                    โดยประเมินราคาอย่างเป็นธรรม</b> ท่านจะได้รับเงินสดในทันทีเต็มจำนวน 
                    และท่านสามารถวางแผนการผ่อนชำระค่าบริการได้ด้วยตัวท่านเอง
                </p>
                <ul className="list-no-bullet">
                    <li>
                        ✔ รับจำนำ กล้อง DSLR, กล้อง Canon , กล้อง NIKON, กล้อง SONY
                    </li>
                    <li>
                        ✔ รับจำนำ TV, LEDTV, Smart TV, ทีวีรุ่นใหม่ทุกรุ่น
                    </li>
                    <li>
                        ✔ รับจำนำ Notebook, Macbook, Imac, สินค้าไอที
                    </li>
                    <li>
                        ✔ รับจำนำโทรศัพท์ Iphone 8, X, XS, XS MAX, iphone 11, 12 และ iphone รุ่นใหม่
                    </li>
                    <li>
                        ✔ รับจำนำ iPad Pro, iPad Mini, iPad 4, iPad 3
                    </li>
                    <li>
                        ✔ รับจำนำ กระเป๋าแบรนด์เนม Louis Vuitton, CHANEL
                    </li>
                    <li>
                        ✔ รับจำนำนาฬิกา Tag, Rolex, Panerai, Casio, G-Shock
                    </li>
                    <li>
                        ✔ รับจำนำสินค้าอื่นๆ โทรสอบถามได้
                    </li>
                </ul>
                <p>
                    รับจำนำกระเป๋าแบรนด์เนม, รับจำนำกระเป๋าหลุยส์วิตตอง
                    โดยการรับจำนำกระเป๋า Louis Vuitton เพื่อให้ได้ราคาดีขึ้น
                    ควรเตรียมอุปกรณ์ที่เกี่ยวข้องกับกระเป๋ามาด้วย เช่น กล่องแข็ง,
                    ถุงผ้า, สาย, ใบเสร็จ, อะไหล่, ชุดกุญแจต่างๆ
                </p>
            </div>
        )
    }
}

export default MainDescription
